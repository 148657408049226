import React from "react"
import SEO from "../components/Seo"
import Container from "../components/Container"
import Belt from "../components/Belt"
import { Title, Quote } from "../components/typography"
import {
  Container as StaticBlockContainer,
  StaticBlock,
} from "../components/typography/StaticBlock"
import { FigureContainer, Figure } from "../components/Figure"
import behandlungSide_bw from "../images/belt/behandlungSide_bw.jpg"
import behandlungTop from "../images/belt/behandlungTop.jpg"
import praxis from "../images/praxis.png"
import logoEmr from "../images/logoEmr.jpg"
import logoAsca from "../images/logoAsca.jpg"

const BehandlungPage = () => (
  <>
    <SEO
      title="Behandlung"
      keywords={[
        "Homöopathie",
        "Krankheit",
        "Anamnese",
        "Krankheitsgeschichte",
        "Folgekonsultation",
        "Verlauf der Symptome",
        "Repertorium",
        "Fallbearbeitung",
        "Deutsch, Italienisch, Französisch, Englisch, Spanisch",
        "Preise",
        "Krankenkassen anerkannt",
        "EMR, ASCA, EGK, Visana",
        "Zusatzversicherung",
      ]}
      description="Zuerst findet eine Erstkonsultation statt, welche ein bis zwei Stunden dauert. Die Folgekonsultationen finden meistens in einem Abstand von vier Wochen statt. Die Kosten der Behandlung werden von der Zusatzversicherung übernommen. Ich bin EMR, ASCA, EGK, VISANA anerkannt."
    />
    <Belt leftImage={behandlungSide_bw} rightImage={behandlungTop} />
    <Container>
      <Quote cite="Christian Morgenstern">
        Jede Krankheit hat ihren besonderen Sinn, man muss nur herausbekommen,
        wovon.
      </Quote>
      <StaticBlockContainer>
        <StaticBlock>
          <Title>BEHANDLUNG</Title>
          <h3>ERSTGESPRÄCH</h3>
          <p>
            Zuerst findet ein Erstgespräch (Anamnese) statt, welches in der
            Regel 1-2 Stunden dauert, um den Patienten in seiner Ganzheit und
            Individualität zu erfassen. Es werden die aktuellen körperlichen
            Beschwerden, die Krankheitsgeschichte, sowie allgemeine Parameter
            wie Schlaf, Verdauung und Temperaturhaushalt aufgenommen. Für mich
            als Homöopathin ist auch die Persönlichkeit des Patienten, seine
            Lebensgeschichte und seine individuelle Reaktion von grosser
            Bedeutung, um die passende homöopathische Arznei zu finden.
          </p>
          <br />
          <h3>FOLGEKONSULTATION</h3>
          <p>
            Die Folgekonsultationen finden meistens in einem Abstand von vier
            Wochen statt und dauern in der Regel zwischen 30-60 Min. In der
            Folgekonsultation wird der Verlauf der Symptome und das weitere
            Vorgehen gemeinsam besprochen. Bei akuten Krankheiten ist der
            Kontakt häufiger, bis der akute Zustand überwunden ist. Genaue
            Selbstbeobachtung und Schilderung der Symptome ist sehr wertvoll, um
            den Verlauf korrekt einschätzen zu können.
          </p>
        </StaticBlock>
        <StaticBlock>
          <Title>FALLBEARBEITUNG</Title>
          <p>
            Nach dem Gespräch werden die geschilderten Symptome mit Hilfe des
            Repertorium (Sammlung aller Symptome mit den entsprechenden
            Arzneien) sowie der Materia Medica (beschreibung der hom. Mittel)
            ausgewertet und das passende homöopathiche Mittel ausgesucht.
          </p>
        </StaticBlock>
        <StaticBlock>
          <Title>SPRACHEN</Title>
          <p>
            Ich spreche Deutsch, Italienisch, Französisch, Englisch und
            Spanisch.
          </p>
        </StaticBlock>
        <StaticBlock>
          <Title>PREISE</Title>
          <p>
            Mein Stundenansatz beträgt 140.- CHF und wird in Einheiten à fünf
            Minuten abgerechnet. Der Stundenansatz gilt für homöopathische
            Konsultationen und Beratungsgespräche, nachträgliche
            Fallbearbeitungen und Repertorisationen, sowie telefonische
            Konstulationen und E-Mail-Beratungen.
          </p>
        </StaticBlock>
        <StaticBlock>
          <Title>KOSTENÜBERNAHME </Title>
          <p>
            Die Homöopathie ist eine von den Krankenkassen anerkannte
            Heilmethode und wird über die Zusatzversicherung abgerechnet. Als
            registrierte Therapeutin beim EMR und bei der ASCA bin ich von den
            meisten Krankenkassen, inkl. EGK und VISANA, anerkannt. Die
            Übernahme der Kosten hängt von Ihrer abgeschlossenen
            Zusatzversicherung ab. Am Besten klären Sie vor der Konsultation die
            genaue Übernahme der Kosten mit Ihrer Krankenkasse ab.
          </p>
          <FigureContainer justifyContent="flex-start" alignItems="center">
            <Figure src={logoEmr} alt="EMR" width={180} />
            <Figure src={logoAsca} alt="ASCA" width={200} />
          </FigureContainer>
        </StaticBlock>
      </StaticBlockContainer>
    </Container>
  </>
)

export default BehandlungPage
